export default ({ $config: { maintenanceMode }, app, redirect, route }) => {
  const maintenanceRoutePath = app.localePath({ name: "maintenance" });

  if (maintenanceMode === "on") {
    if (route.path !== maintenanceRoutePath) redirect(maintenanceRoutePath);
  } else if (maintenanceMode === "off") {
    if (route.path === maintenanceRoutePath)
      redirect(app.localePath({ name: "index" }));
  }
};
