import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _a1b61794 = () => interopDefault(import('../pages/agents.vue' /* webpackChunkName: "pages/agents" */))
const _bad983b0 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _7c3850e5 = () => interopDefault(import('../pages/casting-directors.vue' /* webpackChunkName: "pages/casting-directors" */))
const _46ab239b = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _2c851794 = () => interopDefault(import('../pages/legal-notice.vue' /* webpackChunkName: "pages/legal-notice" */))
const _90ac88e2 = () => interopDefault(import('../pages/talents.vue' /* webpackChunkName: "pages/talents" */))
const _b548ef9e = () => interopDefault(import('../pages/design/icons.vue' /* webpackChunkName: "pages/design/icons" */))
const _7498b5da = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _23655932 = () => interopDefault(import('../pages/*.vue' /* webpackChunkName: "pages/*" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/agents",
    component: _a1b61794,
    name: "agents___fr"
  }, {
    path: "/contact",
    component: _bad983b0,
    name: "contact___fr"
  }, {
    path: "/directeurs-de-casting",
    component: _7c3850e5,
    name: "casting-directors___fr"
  }, {
    path: "/maintenance",
    component: _46ab239b,
    name: "maintenance___fr"
  }, {
    path: "/mentions-legales",
    component: _2c851794,
    name: "legal-notice___fr"
  }, {
    path: "/talents",
    component: _90ac88e2,
    name: "talents___fr"
  }, {
    path: "/design/icons",
    component: _b548ef9e,
    name: "design-icons___fr"
  }, {
    path: "/",
    component: _7498b5da,
    name: "index___fr"
  }, {
    path: "/*",
    component: _23655932,
    name: "*___fr"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
