<template>
  <component
    :is="`h${level}`"
    :class="['title', `title-${level}`]"
  >
    <slot />
  </component>
</template>

<script>
export default {
  name: "BaseTitle",

  props: {
    level: {
      type: String,
      required: true,
      validator: (value) => ["1", "2", "3", "4"].includes(value),
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  &-1 {
    color: color('white');
    font-size: font(huge);
    font-weight: 300;

    @include breakpoint(medium) {
      font-size: font(3.2);
    }
  }

  &-2 {
    font-size: font(huge);
    font-weight: 300;

    @include breakpoint(medium) {
      font-size: font(3.2);
    }
  }

  &-3 {
    font-size: font(2.4);
    font-weight: 800;

    @include breakpoint(medium) {
      font-size: font(important);
    }
  }
}
</style>
