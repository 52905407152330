import Monitoring from "@/utils/classes/Monitoring";

export default class Missive {
  constructor(service, i18n, sentry) {
    this.service = service;
    this.i18n = i18n;
    this.sentry = sentry;
  }

  success(key) {
    this.service.success(this.i18n.t(`success.${key}`));
  }

  error(error) {
    let monitoring = new Monitoring(this.sentry);
    monitoring.exception({
      error,
      from: "Missive",
    });

    const { errors, type } = error;

    if (!errors || !errors.length) {
      this.service.error(this.i18n.t(`error.${type}.default`));
      return;
    }

    errors.forEach((item) => {
      const { errorMessage, error, parameters, field } = item;
      if (!errorMessage && !error) return;

      let message = this.i18n.t(
        `error.${type}.${errorMessage || error}`,
        parameters,
      );

      if (field) {
        const key =
          type === "formError"
            ? field
            : this.i18n.t(`constants.labels.${field}`);

        message = `${key} : ${message}`;
      }

      this.service.error(message);
    });
  }
}
