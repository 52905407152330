import Vue from "vue";
import VueGtag from "vue-gtag";

export default function ({
  app: {
    $config: { googleAnalyticsId },
    router,
  },
}) {
  Vue.use(
    VueGtag,
    {
      config: {
        id: googleAnalyticsId,
        enabled: false,
      },
    },
    router,
  );
}
