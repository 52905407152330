<template>
  <svg
    class="loaderSecondary"
    viewBox="0 0 66 66"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      class="loaderSecondary-circle"
      fill="none"
      stroke-width="6"
      stroke-linecap="round"
      cx="33"
      cy="33"
      r="30"
    />
  </svg>
</template>

<script>
export default {
  name: "AppLoaderSecondary",
};
</script>

<style lang="scss" scoped>
.loaderSecondary {
  @include square(size(important));

  position: absolute;

  animation: animation(
    rotator,
    duration(very-slow),
    timing(linear),
    $iteration: infinite
  );

  &-circle {
    stroke: color('white');
    stroke-dasharray: $dasharray-loader;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation: animation(
      dash,
      duration(very-slow),
      timing(in-out),
      $iteration: infinite
    );
  }
}
</style>
