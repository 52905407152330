import qs from "qs";

export default class Service {
  constructor(endpoint, parameters = {}, axios) {
    this.endpoint = endpoint;
    this.parameters = parameters;
    this.axios = axios;
  }

  setParameters(parameters) {
    this.parameters = [];

    Object.keys(parameters).forEach((key) => {
      this.parameters[key] = parameters[key];
    });

    return this;
  }

  setParameter(parameter, value) {
    this.parameters = [];

    this.parameters[parameter] = value;

    return this;
  }

  removeParameters(parameters) {
    parameters.forEach((parameter) => {
      delete this.parameters[parameter];
    });

    return this;
  }

  removeParameter(parameter) {
    delete this.parameters[parameter];

    return this;
  }

  submit(method, url, data = null, config = null) {
    return this.axios.request({
      url,
      method,
      data,
      config,
    });
  }

  all() {
    return this.submit("get", `/${this.endpoint}`);
  }

  find(id) {
    return this.submit("get", `/${this.endpoint}/${id}`);
  }

  create(item) {
    return this.submit("post", `/${this.endpoint}`, item);
  }

  update(id, item) {
    return this.submit("put", `/${this.endpoint}/${id}`, item);
  }

  destroy(id) {
    return this.submit("delete", `/${this.endpoint}/${id}`);
  }

  upload(fileName, formData) {
    return this.submit("post", `/${this.endpoint}/${fileName}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  getParameterString() {
    return `?${qs.stringify(this.parameters, {
      arrayFormat: "brackets",
    })}`;
  }
}
