<template>
  <footer class="footer">
    <div class="footer-main">
      <i18n
        path="components.footer.title"
        tag="base-title"
        level="2"
      >
        <br class="bp-medium-hidden" />
      </i18n>

      <div class="footer-pages">
        <div
          v-for="page in filteredPages"
          :key="page"
          class="footer-page"
          :class="page"
        >
          <base-title level="3">
            {{ $t(`components.footer.titles.${page}`) }}
          </base-title>

          <base-text class="footer-page-text">
            {{ $t(`components.footer.texts.${page}`) }}
          </base-text>

          <base-link
            :route-name="slugify(page)"
            class="footer-page-link withArrow"
          >
            {{ $t('components.footer.links.more') }}
          </base-link>
        </div>
      </div>
    </div>

    <div class="footer-secondary">
      <base-link
        :title="$t('components.header.links.home')"
        route-name="index"
        class="footer-logo"
      >
        <base-image
          src="logo.png"
          alt="Troupers"
          class="footer-image"
        />
      </base-link>

      <app-list
        :items="links"
        class="footer-links"
      >
        <template #item="{ item: { link, text, isExternal } }">
          <a
            v-if="isExternal"
            :href="link"
            class="link footer-link"
          >
            {{ text }}
          </a>

          <base-link
            v-else
            :route-name="link"
            class="footer-link"
          >
            {{ text }}
          </base-link>
        </template>
      </app-list>
    </div>
  </footer>
</template>

<script>
import { slugify } from "@/utils/functions/string";
import AppList from "@/components/App/List/AppList";

export default {
  name: "AppFooter",

  components: {
    AppList,
  },

  data() {
    return {
      pages: ["talents", "castingDirectors", "agents"],

      links: [
        {
          link: "contact",
          text: this.$t("components.footer.links.becomingPartner"),
        },
        {
          link: "legal-notice",
          text: this.$t("components.footer.links.legalNotice"),
        },
        {
          link: "contact",
          text: this.$t("components.footer.links.contact"),
        },
        {
          link: "https://twitter.com/Troupers_fr",
          text: this.$t("components.footer.links.twitter"),
          isExternal: true,
        },
        {
          link: "https://www.instagram.com/troupers.fr",
          text: this.$t("components.footer.links.instagram"),
          isExternal: true,
        },
        {
          link: "https://www.facebook.com/troupers.fr",
          text: this.$t("components.footer.links.facebook"),
          isExternal: true,
        },
      ],

      slugify,
    };
  },

  computed: {
    filteredPages() {
      return this.pages.filter((page) => {
        const slug = slugify(page);
        const regex = new RegExp(slug);

        return !regex.test(this.$route.name);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  padding: size(16) size(main) size(22);
  color: color('white');
  background: color('black');

  @include breakpoint(medium) {
    padding: size(main) size(medium) size(biggest);
  }

  &-main {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    padding-bottom: size(16);

    @include breakpoint(medium) {
      display: block;
      padding-bottom: size(biggest);
    }
  }

  &-pages {
    @include breakpoint(medium) {
      margin-top: size(biggest);
    }
  }

  &-page {
    --gradient: #{linear-gradient(90deg, color('black') 20%, transparent 60%)};

    padding: size(biggest);

    background-repeat: no-repeat;
    background-size: cover;

    @include breakpoint(medium) {
      padding: size(big);
    }

    &:not(:last-child) {
      margin-bottom: size(biggest);

      @include breakpoint(medium) {
        margin-bottom: size(medium);
      }
    }

    &.talents {
      background-image: var(--gradient), url('~assets/images/footer/talent.jpg');
    }

    &.castingDirectors {
      background-image: var(--gradient),
        url('~assets/images/footer/casting-director.jpg');
    }

    &.agents {
      background-image: var(--gradient), url('~assets/images/footer/agent.jpg');
    }

    &-text {
      font-size: font(important);

      @include breakpoint(medium) {
        font-size: font(medium);
      }
    }

    &-link {
      margin-top: size(medium);
      color: color('primary');
      font-size: font(important);

      @include breakpoint(medium) {
        margin-top: size(biggest);
        font-size: font(medium);
      }
    }
  }

  &-secondary {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding-top: size(16);
    border-top: border(color('white', 0.2));

    @include breakpoint(medium) {
      display: block;
      padding-top: size(biggest);
    }
  }

  &-logo {
    align-self: center;
    justify-self: center;
  }

  &-image {
    height: size(main);
  }

  &-links {
    columns: 2;

    @include breakpoint(medium) {
      margin-top: size(biggest);
    }

    ::v-deep {
      .list-item {
        &:not(:last-child) {
          margin-bottom: size(medium);

          @include breakpoint(medium) {
            margin-bottom: size(big);
          }
        }
      }
    }
  }

  &-link {
    color: color('white');
    font-size: font(important);

    @include breakpoint(medium) {
      font-size: font(medium);
    }

    &:hover,
    &:focus {
      color: color('primary');
    }
  }
}
</style>
