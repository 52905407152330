<template>
  <p class="text">
    <slot />
  </p>
</template>

<script>
export default {
  name: "BaseText",
};
</script>

<style>
.text {
  line-height: 1.5em;

  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: manual;
}
</style>
