<template>
  <div class="layout">
    <app-header-default />

    <nuxt v-if="show" />

    <cookie-control locale="fr" />

    <app-footer />
  </div>
</template>

<script>
import AppHeaderDefault from "@/components/App/Header/AppHeaderDefault.vue";
import AppFooter from "@/components/App/Footer/AppFooter.vue";

export default {
  name: "DefaultLayout",

  components: {
    AppHeaderDefault,
    AppFooter,
  },

  data() {
    return {
      show: true,
    };
  },

  head() {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
    };
  },

  watch: {
    $route(newValue) {
      this.show = false;

      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
};
</script>
