import Missive from "@/utils/classes/Missive";

export default function ({ $axios, app }) {
  $axios.onError((error) => {
    const { data } = error.response;
    const { $toast, i18n, $sentry } = app;

    switch (data.type) {
      // Redirect to 404 page if not found
      case "notFoundError":
        app.context.error({ statusCode: 404 });
        break;
      // Display a toast with the translated error by default
      default:
        new Missive($toast, i18n, $sentry).error(data);
    }
  });
}
