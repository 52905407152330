import { MAX_SIZES } from "@/utils/constants/medias";

class FormValidate {
  constructor(missive) {
    this.missive = missive;
  }

  validate(event) {
    const form = event.target;

    // Reset invalid state
    form
      .querySelectorAll(".invalid")
      .forEach((element) => element.classList.remove("invalid"));

    // Get all invalid fields and convert NodeList to Array
    const invalidFields = Array.from(
      form.querySelectorAll("input:invalid, textarea:invalid, select:invalid"),
    );

    // Add .invalid to all invalid fields
    invalidFields.forEach((invalidField) => {
      if (invalidField.id === "addressAutocomplete") {
        invalidField.closest(".formElementAddress").classList.add("invalid");
      }
      invalidField.classList.add("invalid");
    });

    // Classic checks based on native Constraint Validation API
    this.checkRequiredFields(invalidFields);
    this.checkPatternFields(invalidFields);
    this.checkRangeOverflowFields(invalidFields);
    this.checkTypeFields(invalidFields);
    this.checkBadInputFields(invalidFields);
    this.checkMinLengthFields(invalidFields);
    this.checkMaxLengthFields(invalidFields);

    // Check on custom fields
    const customFields = Array.from(form.querySelectorAll(".multiselect"));
    this.checkRequiredCustomFields(customFields);

    // Advanced checks on files -> File type and size
    const fileFields = Array.from(form.querySelectorAll("input[type=\"file\"]"));
    this.checkFileTypeFields(fileFields);
    this.checkFileSizeFields(fileFields);

    // Check for password confirmation
    const password = form.querySelector(
      "div[data-password=\"password\"] input[type=\"password\"]",
    );
    const confirm = form.querySelector(
      "div[data-password=\"confirmPassword\"] input[type=\"password\"]",
    );
    this.checkSamePassword(password, confirm);

    // Form is invalid
    if (!form.checkValidity()) event.preventDefault();

    return form.checkValidity();
  }

  dispatchError(field, error) {
    this.missive.error({
      type: "formError",
      errors: [
        {
          field: field.split("(")[0],
          error,
        },
      ],
    });
  }

  checkRequiredFields(invalidFields) {
    const invalidRequiredFields = invalidFields.filter(
      (invalidField) => invalidField.validity.valueMissing,
    );

    if (invalidRequiredFields.length === 0) return;

    invalidRequiredFields.forEach((invalidRequiredField) =>
      this.dispatchError(
        invalidRequiredField.labels[0].textContent,
        "requiredField",
      ),
    );
  }

  checkPatternFields(invalidFields) {
    const invalidPatternFields = invalidFields.filter(
      (invalidField) => invalidField.validity.patternMismatch,
    );

    if (invalidPatternFields.length === 0) return;

    invalidPatternFields.forEach((invalidPatternField) =>
      this.dispatchError(
        invalidPatternField.labels[0].textContent,
        "patternMismatch",
      ),
    );
  }

  checkRangeOverflowFields(invalidFields) {
    const invalidRangeOverflowFields = invalidFields.filter(
      (invalidField) => invalidField.validity.rangeOverflow,
    );

    if (invalidRangeOverflowFields.length === 0) return;

    invalidRangeOverflowFields.forEach((invalidRangeOverflowField) =>
      this.dispatchError(
        invalidRangeOverflowField.labels[0].textContent,
        "rangeOverflow",
      ),
    );
  }

  checkTypeFields(invalidFields) {
    const invalidTypeFields = invalidFields.filter(
      (invalidField) => invalidField.validity.typeMismatch,
    );

    if (invalidTypeFields.length === 0) return;

    invalidTypeFields.forEach((invalidTypeField) =>
      this.dispatchError(
        invalidTypeField.labels[0].textContent,
        "typeMismatch",
      ),
    );
  }

  checkBadInputFields(invalidFields) {
    const invalidTypeFields = invalidFields.filter(
      (invalidField) => invalidField.validity.badInput,
    );

    if (invalidTypeFields.length === 0) return;

    invalidTypeFields.forEach((invalidTypeField) =>
      this.dispatchError(invalidTypeField.labels[0].textContent, "badInput"),
    );
  }

  checkMinLengthFields(invalidFields) {
    const invalidMinLengthFields = invalidFields.filter(
      (invalidField) => invalidField.validity.tooShort,
    );

    if (invalidMinLengthFields.length === 0) return;

    invalidMinLengthFields.forEach((invalidMinLengthField) =>
      this.dispatchError(
        invalidMinLengthField.labels[0].textContent,
        "tooShort",
      ),
    );
  }

  checkMaxLengthFields(invalidFields) {
    const invalidMaxLengthFields = invalidFields.filter(
      (invalidField) => invalidField.validity.tooLong,
    );

    if (invalidMaxLengthFields.length === 0) return;

    invalidMaxLengthFields.forEach((invalidMaxLengthField) =>
      this.dispatchError(
        invalidMaxLengthField.labels[0].textContent,
        "tooLong",
      ),
    );
  }

  checkRequiredCustomFields(customFields) {
    const invalidRequiredCustomFields = customFields.filter(
      (customField) =>
        Boolean(customField.dataset.required) && !customField.dataset.value,
    );

    if (invalidRequiredCustomFields.length === 0) {
      // Reset form validity
      customFields.forEach((customField) => {
        customField.querySelector("input").setCustomValidity("");
      });

      return;
    }

    invalidRequiredCustomFields.forEach((invalidRequiredCustomField) => {
      const error = "requiredField";

      invalidRequiredCustomField
        .querySelector("input")
        .setCustomValidity(error);

      invalidRequiredCustomField.classList.add("invalid");

      this.dispatchError(
        invalidRequiredCustomField.nextElementSibling.textContent,
        error,
      );
    });
  }

  checkFileTypeFields(fileFields) {
    if (fileFields.length === 0) return;

    fileFields.forEach((fileField) => {
      if (fileField.files.length === 0) return;

      const type = fileField.files[0].type;
      const accept = fileField.getAttribute("accept");

      const isValid = accept.includes(type);

      if (!isValid) {
        const error = "invalidFormat";
        fileField.setCustomValidity(error);
        this.dispatchError(fileField.getAttribute("name"), error);
      }
    });
  }

  checkFileSizeFields(fileFields) {
    if (fileFields.length === 0) return;

    fileFields.forEach((fileField) => {
      if (fileField.files.length === 0) return;

      const size = fileField.files[0].size; // in octets
      const convertedSize = size / 1000000; // octets to Mega octets
      const ceiledSize = Math.ceil(convertedSize); // We want the smallest integer greater than or equal to the given size
      const type = fileField.files[0].type.split("/")[0].toUpperCase();

      const isValid = ceiledSize <= MAX_SIZES[type];

      if (!isValid) {
        const error = "fileTooBig";
        fileField.setCustomValidity(error);
        this.dispatchError(fileField.getAttribute("name"), error);
      }
    });
  }

  checkSamePassword(password, confirm) {
    if (!password || !confirm) return;

    const isValid = password.value === confirm.value;

    if (!isValid) {
      confirm.classList.add("invalid");
      confirm.setCustomValidity("Password mismatch");
      this.dispatchError("passwordMismatch", confirm.getAttribute("name"));
    } else {
      confirm.classList.remove("invalid");
      confirm.setCustomValidity("");
    }
  }
}

export default FormValidate;
