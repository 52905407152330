<template>
  <input
    :value="value"
    class="input"
  />
</template>

<script>
export default {
  name: "BaseInput",

  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    focus: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    if (this.focus) this.$el.focus();
  },
};
</script>
