<template>
  <header class="header">
    <slot />
  </header>
</template>

<script>
export default {
  name: "AppHeader",
};
</script>
