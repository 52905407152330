<template>
  <hr class="divider" />
</template>

<script>
export default {
  name: "BaseDivider",
};
</script>

<style lang="scss" scoped>
.divider {
  color: color('grey-medium');
  border-color: currentColor;
  border-bottom: none;
  opacity: 0.5;
}
</style>
