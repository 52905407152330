export default class Monitoring {
  constructor(service) {
    this.service = service;
  }

  exception({ error, from }) {
    this.service.withScope((scope) => {
      // Set tags
      if (from) {
        scope.setTag("error.from", from);
      }
      scope.setTag("error.type", error.type);
      if (error.status) {
        scope.setTag("error.status", error.status);
      }
      if (document.referrer) {
        scope.setTag("referrer", document.referrer);
      }

      // Set levels
      switch (error.type) {
        case "notFoundError":
          scope.setLevel("warning");
          break;
        case "formError":
          scope.setLevel("info");
          break;
      }

      // Add breadcrumb
      scope.addBreadcrumb({
        data: error,
        category: error.type,
        level: "info",
      });

      this.service.captureException(error);
    });
  }

  message(message) {
    this.service.captureMessage(message);
  }
}
