<template>
  <app-header
    :class="{ dark: isDark }"
    class="headerDefault"
  >
    <base-button
      :aria-label="mobileMenuText"
      class="bp-medium-only"
      @click="toggleDisplayMobileMenu"
    >
      <base-icon
        icon="menu"
        class="headerDefault-menuIcon"
      />
    </base-button>

    <!-- Logo -->
    <base-link
      :title="$t('components.header.links.home')"
      route-name="index"
    >
      <base-image
        src="logo.png"
        alt="Troupers"
        class="headerDefault-logo"
      />
    </base-link>

    <app-nav :display-mobile-menu="displayMobileMenu" />
  </app-header>
</template>

<script>
import AppHeader from "@/components/App/Header/AppHeader.vue";
import AppNav from "@/components/App/Nav/AppNav.vue";

export default {
  name: "AppHeaderDefault",

  components: {
    AppHeader,
    AppNav,
  },

  data() {
    return {
      regex: /talents|casting-directors|agents/,
      isDark: false,

      displayMobileMenu: false,
    };
  },

  computed: {
    mobileMenuText() {
      return this.displayMobileMenu
        ? this.$t("components.header.buttons.menu.close")
        : this.$t("components.header.buttons.menu.open");
    },
  },

  watch: {
    $route(newValue) {
      this.isDark = !this.regex.test(newValue.name);

      this.displayMobileMenu = false;
    },
  },

  created() {
    this.isDark = !this.regex.test(this.$route.name);
  },

  methods: {
    toggleDisplayMobileMenu() {
      this.displayMobileMenu = !this.displayMobileMenu;
    },
  },
};
</script>

<style lang="scss" scoped>
.headerDefault {
  position: absolute;
  top: 0;
  left: 0;

  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
  gap: size(main);

  width: 100%;
  padding: size(main);

  &.dark {
    position: initial;
    background: color('black');

    @include breakpoint(medium) {
      position: sticky;
    }
  }

  @include breakpoint(medium) {
    position: sticky;
    z-index: zIndex(sticky);

    grid-template-columns: min-content 1fr min-content;
    gap: size(medium);

    padding: size(big) size(medium);
    background: color('black');
  }

  &-menuIcon.icon {
    @include square(size(big));
    color: color("white");
  }

  &-logo {
    height: size(main);
  }
}
</style>
