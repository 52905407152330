<template>
  <label class="label">
    <slot />
  </label>
</template>

<script>
export default {
  name: "BaseLabel",
};
</script>

<style lang="scss" scoped>
.label {
  position: relative;
  display: block;
  padding: 0 size(small);
  margin-left: size(small);
  background: color('white');
  color: color('grey-dark');
  transition: transition();
}
</style>
