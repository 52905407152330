export default ({ app, redirect, route }) => {
  const path = route.path.substring(1);
  let name;

  // Redirect legacy /formulaire/contact to /contact
  if (/formulaire\/contact/g.test(path)) {
    name = "contact";
  }

  // Redirect legacy /en & /fr to /
  if (/^fr$/g.test(path) || /^en$/g.test(path)) {
    name = "index";
  }

  if (name) redirect(app.localePath({ name }));
};
