import Service from "./Service";

export default class PublicService extends Service {
  constructor(parameters = {}, axios) {
    super("public", parameters, axios);
  }

  sendContact(payload) {
    return this.submit("post", `${this.endpoint}/contact`, payload);
  }
}
