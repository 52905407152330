export default {
  getPageType(state) {
    return state.pageType;
  },

  getContactObjects(state) {
    return state.config.contact.objects.map((object) => ({
      value: object,
      text: `constants.contact.objects.${object}`,
    }));
  },

  getContactProfiles(state) {
    return state.config.contact.profiles.map((profile) => ({
      value: profile,
      text: `constants.contact.profiles.${profile}`,
    }));
  },
};
