<template>
  <nav class="nav">
    <!-- Desktop -->
    <app-list
      :items="navItems"
      class="nav-list bp-medium-hidden"
    >
      <template #item="{ item }">
        <a
          v-if="item.external"
          :href="item.route"
          :class="item.class"
          class="link link-nav"
        >
          {{ item.name }}
        </a>

        <base-link
          v-else
          :title="item.name"
          :route-name="item.route"
          :class="item.class"
          class="link-nav"
        >
          {{ item.name }}
        </base-link>
      </template>
    </app-list>

    <!-- Mobile -->
    <app-list
      v-if="displayMobileMenu"
      :items="navItems"
      class="nav-list"
    >
      <template #item="{ item }">
        <a
          v-if="item.external"
          :href="item.route"
          :class="item.class"
          class="link link-nav"
        >
          {{ item.name }}
        </a>

        <base-link
          v-else
          :title="$t(item.name)"
          :route-name="item.route"
          :class="item.class"
          class="link-nav"
        >
          {{ $t(item.name) }}
        </base-link>
      </template>
    </app-list>

    <a
      :href="registerLink"
      class="link link-nav link-register"
    >
      {{ this.$t('components.nav.items.register') }}
    </a>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";
import AppList from "@/components/App/List/AppList";

export default {
  name: "AppNav",

  components: {
    AppList,
  },

  props: {
    displayMobileMenu: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      navItems: [
        {
          name: this.$t("components.nav.items.talents"),
          route: "talents",
        },
        {
          name: this.$t("components.nav.items.castingDirectors"),
          route: "casting-directors",
        },
        {
          name: this.$t("components.nav.items.agents"),
          route: "agents",
        },
        {
          name: this.$t("components.nav.items.login"),
          route: `${this.$config.appUrl}/login`,
          external: true,
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      pageType: "config/getPageType",
    }),

    registerLink() {
      const entity = this.pageType?.slice(0, -1) ?? null;

      return `${this.$config.appUrl}/register/?tab=${entity}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.nav {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: size(main);

  &-list {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    gap: size(main);

    text-align: center;

    @include breakpoint(medium) {
      position: absolute;
      top: 100%;
      left: 0;

      grid-auto-flow: initial;
      justify-content: center;

      width: 100%;
      padding: size(big) size(medium);
      background: color('black');
    }
  }
}
</style>
