import { render, staticRenderFns } from "./BaseTitle.vue?vue&type=template&id=f0dfd342&scoped=true&"
import script from "./BaseTitle.vue?vue&type=script&lang=js&"
export * from "./BaseTitle.vue?vue&type=script&lang=js&"
import style0 from "./BaseTitle.vue?vue&type=style&index=0&id=f0dfd342&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f0dfd342",
  null
  
)

export default component.exports