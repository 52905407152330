<template>
  <app-error :error="error" />
</template>

<script>
import AppError from "@/components/App/Error/AppError";

export default {
  name: "Error",

  components: {
    AppError,
  },

  props: {
    error: {
      type: Object,
      required: true,
    },
  },
};
</script>
