<template>
  <button
    :disabled="$attrs.disabled || isLoading"
    :class="{ loading: isLoading }"
    class="button"
    type="button"
    @click.stop="emitClick"
  >
    <app-loader-secondary v-if="isLoading" />

    <div class="button-content">
      <slot />
    </div>
  </button>
</template>

<script>
import AppLoaderSecondary from "@/components/App/Loader/AppLoaderSecondary";

export default {
  name: "BaseButton",

  components: {
    AppLoaderSecondary,
  },

  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    emitClick(event) {
      this.$emit("click", event);
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  @include gt-america();

  // Reset styles
  cursor: pointer;
  display: inline-block;
  color: color('black');
  background: none;
  border: none;
  transition: transition();

  &:hover,
  &:focus,
  &:active {
    outline: none;
  }

  &:disabled {
    cursor: default;
    opacity: 0.3;
  }

  // Small hack to remove dotted border in Firefox when button is focused
  &::-moz-focus-inner {
    padding: 0;
    border: 0;
  }

  &.loading {
    display: flex;
    align-items: center;
    justify-content: center;

    .button-content {
      opacity: 0;
    }
  }

  &-default {
    font-size: font(medium);
    color: color('white');
    background: color('black');
    border-radius: size();
    padding: size(intermediate) size(big);

    &:not(:disabled) {
      &:hover,
      &:focus,
      &:active {
        box-shadow: shadow(
          $y: size(),
          $blur: size(small),
          $color: color('black', 0.5)
        );
      }
    }
  }
}
</style>
