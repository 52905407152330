<template>
  <ul class="list">
    <li
      v-for="(item, index) in items"
      :key="index"
      class="list-item"
    >
      <slot
        v-if="item"
        :item="item"
        :index="index"
        name="item"
      />
    </li>
  </ul>
</template>

<script>
export default {
  name: "AppList",

  props: {
    items: {
      type: [Array, Number],
      required: true,
    },
  },
};
</script>

<style lang="scss">
.list {
  list-style: none;
}
</style>
