import Vue from "vue";

const requireIcon = require.context(
  // The relative path of the components folder
  "~/assets/icons",
  // Whether or not to look in subfolders
  false,
  // The regular expression used to match base component filenames
  /icon-[a-z]+\.(svg)$/,
);

requireIcon.keys().forEach((fileName) => {
  let iconConfig = requireIcon(fileName);
  iconConfig = iconConfig.default || iconConfig;
  const iconName =
    iconConfig.name || fileName.replace(/^.+\//, "").replace(/\.\w+$/, "");
  Vue.component(iconName, iconConfig);
});
