<template>
  <component
    :is="require(`@/assets/icons/icon-${icon}.svg?inline`).default"
    :class="['icon', `icon-${icon}`]"
  />
</template>

<script>
export default {
  name: "BaseIcon",

  props: {
    icon: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  display: block;

  width: 100%;
  height: 100%;

  fill: none;
  stroke: currentColor;
  transition: transition();
}
</style>
