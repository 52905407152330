<template>
  <div class="error">
    <div class="error-wrapper">
      <base-title
        level="2"
        class="error-title"
      >
        <!-- 404 -->
        <template v-if="statusCode == 404">
          {{ $t('pages.error.errors.message.404') }}
        </template>

        <template v-else>
          {{ $t('pages.error.errors.message.500') }}
        </template>
      </base-title>

      <div class="horizontal-grid">
        <base-link
          route-name="index"
          class="link-rounded"
        >
          {{ $t('pages.error.links.index') }}
        </base-link>

        <base-button
          class="button-default"
          @click="report"
        >
          {{ $t('pages.error.buttons.sendReport') }}
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppError",

  props: {
    error: {
      type: Object,
      required: true,
    },
  },

  computed: {
    statusCode() {
      return (this.error && this.error.statusCode) || 500;
    },
  },

  mounted() {
    this.$monitoring.exception({
      error: this.error,
      from: "Error page",
    });
  },

  methods: {
    report() {
      this.$monitoring.report(this.$t("pages.error.report.title"));
    },
  },
};
</script>

<style lang="scss" scoped>
.error {
  display: grid;
  justify-items: center;
  align-items: center;

  padding: size(main);
  height: calc(100vh - #{size(header)});

  @include breakpoint(medium) {
    padding: 0 size(medium);
  }

  .horizontal-grid {
    @include breakpoint(medium) {
      grid-auto-flow: row;
      justify-items: center;
    }
  }

  &-wrapper {
    display: grid;
    grid-gap: size(big);
    justify-items: center;

    padding: size(main);
    background: color('white');
    border-radius: size();

    @include breakpoint(medium) {
      padding: size(big);
    }
  }

  &-title {
    text-align: center;
    font-weight: 800;
    font-size: font(3.4);

    @include breakpoint(medium) {
      font-size: font(important);
    }
  }
}
</style>

<style lang="scss">
.sentry-error-embed-wrapper {
  .form-submit {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  .btn {
    background: color('black') !important;
    border-radius: size();
    margin: 0 !important;
  }

  .powered-by {
    display: none;
  }
}
</style>
