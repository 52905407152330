<template>
  <nuxt-link
    :to="localePath({ name: routeName, params: params, query: query })"
    class="link"
  >
    <slot />
  </nuxt-link>
</template>

<script>
export default {
  name: "BaseLink",

  props: {
    routeName: {
      type: String,
      default: null,
    },
    params: {
      type: Object,
      default: () => ({}),
    },
    query: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style lang="scss">
.link {
  color: color('black');
  display: inline-block;
  outline: none;
  text-decoration: none;
  transition: transition();

  &[disabled] {
    opacity: 0.3;
    pointer-events: none;
    cursor: default;
  }

  &.nuxt-link-exact-active {
    cursor: default;
  }

  &.withArrow {
    &::after {
      content: '→';
      display: inline-block;
      transition: transition();
    }

    &:hover,
    &:focus {
      &::after {
        transform: translateX(size(intermediate));
      }
    }
  }

  &-primary {
    padding: size(big) size(main);
    font-size: font(2.4);

    color: color('black');
    background: color('primary');
    border: border(color('primary'));

    &.black {
      color: color('white');
      background: color('black');
      border: border(color('black'));
    }

    @include breakpoint(medium) {
      padding: size(medium) size(big);
      font-size: font(important);
    }
  }

  &-nav {
    color: color('white');
    font-size: font(important);

    &:hover,
    &:focus,
    &.nuxt-link-active {
      color: color('primary');
    }
  }

  &-register {
    color: color('primary');
    padding: size(medium) size(important);
    border: border(color('primary'));

    &:hover,
    &:focus {
      color: color('white');
      background: color('primary');
    }

    @include breakpoint(medium) {
      padding: size(intermediate) size(medium);
      font-size: font(medium);
    }
  }

  &-rounded {
    border: border();
    border-radius: size();
    padding: size(intermediate) size(big);
    font-size: font(medium);

    &:not(:disabled) {
      &:hover,
      &:focus,
      &:active {
        background: color('black');
        color: color('white');
      }
    }
  }
}
</style>
