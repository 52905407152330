<template>
  <picture class="picture">
    <source
      :srcset="require(`@/assets/images/${src}?webp`)"
      type="image/webp"
    />

    <source
      :srcset="require(`@/assets/images/${src}`)"
      :type="type"
    />

    <img
      :src="require(`@/assets/images/${src}?lqip`).default"
      :data-src="require(`@/assets/images/${src}`).default"
      :alt="alt"
      class="picture-image lazyload"
      loading="lazy"
    />
  </picture>
</template>

<script>
export default {
  name: "BaseImage",

  props: {
    src: {
      type: String,
      default: null,
    },
    alt: {
      type: String,
      default: null,
    },
  },

  computed: {
    type() {
      let ext = this.src.split(".").pop();
      ext = ext === "jpg" ? "jpeg" : ext;

      return `image/${ext}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.picture {
  display: inline-block;
  overflow: hidden;

  &-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: transition();

    &.lazyload,
    &.lazyloading {
      opacity: 0;
    }

    &.lazyloaded {
      opacity: 1;
    }
  }
}
</style>
